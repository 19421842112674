import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import RatingStars from "@/components/ui/RatingStars.vue";

const component = (props = {}) => {
  return myRentalsShallowMount(RatingStars, {
    props,
  });
};

describe("RatingStars", () => {
  testMyRentalsShallowMount(RatingStars);

  describe("Computed", () => {
    describe("percentage", () => {
      it.each`
        value | expected
        ${0}  | ${"0%"}
        ${1}  | ${"20%"}
        ${2}  | ${"40%"}
        ${3}  | ${"60%"}
        ${4}  | ${"80%"}
        ${5}  | ${"100%"}
      `("should return $expected for $value value", ({ value, expected }) => {
        // Given
        const RatingStars = component({
          max: 5,
          value,
        });
        // Then
        expect(RatingStars.vm.percentage).toBe(expected);
      });
    });
  });
});
